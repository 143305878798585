import Typography from 'typography'
import theme, { colors } from './typography-theme-iconof'

const typography = new Typography(theme)

// Hot reload typography in development
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

export default typography

export const rhythm = typography.rhythm
export const scale = typography.scale
export { colors }
